import React, { useState, useEffect, useRef } from "react";
import { useSpring, animated } from "react-spring";
import mobileImage from "../assets/homeImage/mobileBg.webp";
import redEllipse from "../assets/homeImage/redEllipse.svg";
import blueEllipse from "../assets/homeImage/blueEllipse.svg";
import yellowEllipse from "../assets/homeImage/yellowEllipse.svg";

const AnimatedEllipse = ({
  src,
  alt,
  className,
  phaseOffset = 0,
  startAnimation,
}) => {
  const [{ xy, scale }, set] = useSpring(() => ({
    xy: [0, 0],
    scale: 1,
    config: { mass: 1, tension: 120, friction: 14 },
  }));

  useEffect(() => {
    if (!startAnimation) return;

    let cancel = false;
    const animate = () => {
      if (cancel) return;
      const angle = Date.now() / 1000 + phaseOffset;
      set({
        xy: [Math.sin(angle) * 9, Math.cos(angle) * 9],
        scale: 1 + Math.sin(angle * 0.5) * 0.16,
      });
      requestAnimationFrame(animate);
    };
    animate();
    return () => {
      cancel = true;
    };
  }, [set, phaseOffset, startAnimation]);

  const springProps = {
    transform: xy.to((x, y) => `translate(${x}px, ${y}px)`),
    scale: scale.to((s) => s),
  };

  return (
    <animated.img
      src={src}
      alt={alt}
      className={className}
      style={startAnimation ? springProps : {}}
    />
  );
};

const DesktopAnimation = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1280);
  const [isVisible, setIsVisible] = useState(false);
  const [startAnimation, setStartAnimation] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), 100);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1280);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (imageRef.current && imageRef.current.complete) {
      setStartAnimation(true);
    }
  }, [isVisible]);

  const handleImageLoad = () => {
    setStartAnimation(true);
  };

  return (
    <div className="relative flex justify-center items-center w-full h-full">
      <div className="absolute inset-0 z-10 overflow-hidden">
        <AnimatedEllipse
          src={redEllipse}
          alt="Red Ellipse"
          className={`absolute w-[125px] h-[125px] top-[25px] right-[-40px] sm:w-[200px] sm:h-[200px] sm:top-[100px] sm:right-[-50px] xl:w-[150px] xl:h-[150px] xl:top-[100px] xl:right-[-75px] 2xl:w-[200.91px] 2xl:h-[200.91px] 2xl:top-[150px] 2xl:right-[-100px] transition-all duration-1000 ease-out
          ${isVisible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"}`}
          phaseOffset={0}
          startAnimation={startAnimation}
        />

        <div className={`absolute w-[300px] h-[300px] left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 sm:w-[400px] sm:h-[400px] xl:w-[350px] xl:h-[350px] 2xl:w-[400px] 2xl:h-[400px]`}>
          <AnimatedEllipse
            src={blueEllipse}
            alt="Blue Ellipse"
            className={`w-full h-full object-contain transition-all duration-1000 ease-out
          ${isVisible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"}`}
            phaseOffset={(Math.PI * 2) / 3}
            startAnimation={startAnimation}
          />
        </div>

        <AnimatedEllipse
          src={yellowEllipse}
          alt="Yellow Ellipse"
          className={`absolute w-[72px] h-[72px] left-[5vw] top-[2vh] md:top-auto bottom-auto sm:w-[126.56px] sm:h-[126.56px] sm:left-[7vw] xl:w-[100px] xl:h-[100px] xl:left-[3vw] 2xl:w-[126.56px] 2xl:h-[126.56px] 2xl:left-[50px] xl:bottom-1/4 transition-all duration-1000 ease-out
          ${isVisible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"}`}
          phaseOffset={(Math.PI * 4) / 3}
          startAnimation={startAnimation}
        />
      </div>

      <img
        ref={imageRef}
        src={mobileImage}
        alt="Mobile Image"
        onLoad={handleImageLoad}
        className={`
          relative z-10 w-[350px] h-[600px] pl-[15px] 
          sm:w-[450px] sm:h-[736px] sm:ml-[25px] 
          xl:w-[450px] xl:h-[736px] xl:ml-[25px] 
          object-contain
          transition-all duration-1000 ease-out
          ${isVisible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-12"}
        `}
      />
    </div>
  );
};

export default DesktopAnimation;
