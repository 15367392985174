import React from "react";
import logo from "../../assets/logo.svg";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link href="/">
      <img src={logo} alt="logo" className="h-[25px] sm:h-[40px] w-[95.71px]" />
    </Link>
  );
};

export default Logo;
