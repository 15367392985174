import posLogo from "../assets/images/projectsImg/campos/posLogo.png";
import displayLogo from "../assets/images/projectsImg/displayScreen/displayScreenLogo.png";
import displayImage1 from "../assets/images/projectsImg/displayScreen/displayScreenImage1.webp";
import displayImage2 from "../assets/images/projectsImg/displayScreen/displayScreenImage2.webp";
import rebbeLogo from "../assets/images/projectsImg/rebbe/rebbeLogo.png";
import rebbeImage1 from "../assets//images/projectsImg/rebbe/rebbeImage1.webp";
import rebbeImage2  from "../assets//images/projectsImg/rebbe/rebbeImage2.webp";
import rebbeImage3 from "../assets//images/projectsImg/rebbe/rebbeImage3.webp";
import camposText from "../assets/images/projectsImg/campos/camposText.png";
import posImage1 from "../assets/images/projectsImg/campos/posImage3.webp";
import posImage2 from "../assets/images/projectsImg/campos/posImage2.webp";
import posImage3 from "../assets/images/projectsImg/campos/posImage1.webp";
export const camposData = {
  name: "campos",
  logo: posLogo,
  logoAlt: "posLogo",
  logoText: camposText,
  logoTextAlt: "posLogo",
  viewAppLink: "www.abc.com",
  heading1: "The Story of CampOS",
  paragraph1:
    "In the past, camp canteens often struggled with manual processes that were time-consuming, error-prone, and inefficient. Managing inventory, processing payments, and tracking sales manually could be a burdensome task for camp staff. This not only consumed valuable time but also increased the likelihood of human error and delays in In the fast-paced environment of a summer camp, efficiency is key—especially when it comes to handling canteen sales. Traditional systems often struggle with reliability, require constant internet connectivity, and are complex to use. These issues can lead to long lines, frustrated staff, and slow service for campers. CampOS, developed by OSFY, offers a powerful solution: a robust, offline-capable kiosk system designed to streamline transactions and make camp canteen operations smoother than ever.",
  heading2: "Kiosk Features that Simplify Transactions",
  paragraph2:
    "At the heart of CampOS is its kiosk interface, designed for fast, easy, and offline transactions. Whether the internet connection is stable or not, CampOS ensures that orders are processed without interruption, providing a seamless buying experience for campers, staff, and administration alike.",
  image1: posImage1,

  orderedList1: [
    {
      item: "Multiple Account Types for Tailored Purchases",
      description:
        "CampOS allows users to manage their funds based on their role at camp:",
      unorderedList: [
        "Campers: At the start of the summer, campers can preload their accounts with funds. This allows them to make cashless purchases throughout the camp, eliminating the need for physical money and making transactions faster.",
        "Staff: Camp staff can access both their paycheck balance (if enabled by the operator) and any personal funds they add. This flexible system ensures staff members have easy access to canteen purchases without the hassle of managing multiple payment methods.",
        "Head Staff and Administration: Items can be sold to this group at cost price, offering an equitable solution for those overseeing camp operations.",
      ],
    },
    {
      item: " Offline Functionality for Reliable Service",
      description:
        "CampOS shines in its ability to function entirely offline. Many camp locations are remote, and reliable internet access is not guaranteed. CampOS allows canteen operators to process orders, update account balances, and maintain smooth service even in offline mode, ensuring no disruptions in service, regardless of connectivity issues.",
    },
    {
      item: " Receipt Printing for Easy Record Keeping",
      description:
        "The system integrates with receipt printers, allowing operators to provide physical receipts to customers with each transaction. This feature supports better tracking and transparency for both the customer and the camp administration.",
    },
  ],

  heading3: "Backend Control for Seamless Management",
  paragraph3:
    "The system integrates with receipt printers, allowing operators to provide physical receipts to customers with each transaction. This feature supports better tracking and transparency for both the customer and the camp administration..",
  image2: posImage2,
  orderedList2: [
    {
      item: "Quick and Simple Setup",
      description:
        "Setting up CampOS takes only 15 minutes, making it incredibly easy for camps to implement. The system offers bulk upload functionality for both customers and products, allowing operators to quickly input camper, staff, and administration accounts along with their assigned roles. Bulk uploading of products with pre-set prices ensures that inventory is managed efficiently from the start.",
    },
    {
      item: "Operator Dashboard for Full Control",
      description:
        "The operator’s dashboard is the control hub of the system. From here, operators can easily manage individual accounts, add funds to specific customer balances, and monitor transactions. Searching through camper and staff accounts is quick and intuitive, ensuring smooth operations even during peak hours.",
    },
    {
      item: "Flexible Account Management",
      description:
        "Operators can add or adjust balances for both staff and campers in just a few clicks. This flexibility allows for real-time updates, making it easy to accommodate requests for balance adjustments throughout the camp season.",
    },
  ],
  image3: posImage3,
  heading4: "CampOS: A Modern Solution for Camp Canteens",
  paragraph4:
    "With its dual focus on a streamlined kiosk experience and a powerful backend management system, CampOS solves the challenges that traditional camp canteen systems face. Its offline capabilities, tailored account management, and fast setup make it the ideal solution for camps looking to modernize their operations.\n By implementing CampOS, camps can offer a faster, more reliable purchasing experience for campers and staff, while also ensuring operators have the tools they need to efficiently manage all aspects of the canteen.",
};

export const displayScreensData = {
  name: "displayScreen",
  logo: displayLogo,
  viewAppLink: "www.abc.com",
  heading1: "The Story of Display Screen Softwear",
  paragraph1: `Giving Dedications a Fresh Look: The New Digital Experience at the 770 Tent.\n At Osfy, we love finding creative ways to make community experiences better. This past Tishrei, we teamed up with the organizers of the 770 Tent in Crown Heights to shake things up and transform how dedications for days of davening and learning are shown during this busy time. With thousands of visitors flocking to the tent, we wanted to make sure every viewer's generosity got the recognition it deserves.`,
  heading2: "The Challenge of Old-School Methods",
  heading3: "Our Collaboration with the Tent Organizers",
  paragraph2:
    "Seeing the need for a modern solution, the tent organizers came to us at Osfy, looking for ways to improve the dedication process. We were excited to help out and created a new software application to tackle these challenges head-on.",

  image1: displayImage1,
  image2: displayImage2,

  orderedList1: [
    {
      // item: "Multiple Account Types for Tailored Purchases",
      description:
        "In the past, dedications were displayed on printed PVC boards, and let’s just say, it had its downsides:",
      unorderedList: [
        "High Costs: Those PVC boards were not cheap, which meant budgets took a hit, limiting the number of dedications we could showcase.",
        "Time Crunch: Boards needed to be printed at least four days in advance, leaving viewers scrambling to get their dedications in on time.",
        "Oops Factor: Once those boards were printed, any typos were permanent unless you wanted to spend more on a reprint.",
        "Labor Setup: Putting up those boards took a lot of effort and space, which often left the viewers feeling a bit overlooked.",
        "Weather Woes: Even though the tent is indoors, the need for durable signage remained key, and wear and tear could make dedications hard to read over time.",
      ],
    },
  ],

  orderedList2: [
    {
      description:
        "With our digital dedication system, we’re bringing a bunch of benefits over the old-school methods:",
      unorderedList: [
        "Cost-Effective: No more pricey PVC printing, so funds can go towards more important things.",
        "Flexibility Galore: Viewers can set up dedications in advance and even make last-minute changes without stressing over print deadlines.",
        "Easy Fixes: If there’s a typo, it can be corrected instantly in the app—no more costly reprints!",
        "More Visibility: With multiple digital screens placed throughout the tent, we can show off more dedications, making sure everyone gets recognized.",
        "Automated Scheduling: Our system has a handy admin dashboard with an easy-to-use calendar feature, so organizers can schedule dedications ahead of time. This means everything runs smoothly, even during Shabbat and Yom Tov when we can’t make changes.",
      ],
    },
  ],

  orderedList3: [
    {
      description:
        "One of the coolest parts of our system is the user-friendly admin dashboard. Designed with a focus on ease of use, it works great on both desktop and mobile devices. Key features include:",
      unorderedList: [
        "On-the-Go Access: Administrators can manage dedications from anywhere, making it super convenient",
        "Real-Time Management: The dashboard lets operators quickly see ongoing dedications and their statuses, keeping everything up-to-date.",
        "Smooth Operations: Managing accounts, adding funds, and keeping track of transactions is a breeze, even when things get busy.",
        "Complete Control: Organizers can easily adjust balances and update info in just a few clicks.",
      ],
    },
  ],

  heading4: "Enter Digital Dedications",
  heading5: "A User-Friendly Admin Dashboard",
  heading6: "Custom Screens for Display",
  paragraph3:
    "On top of the software, Osfy also built and sourced custom screens for the tent. These screens make the dedications pop, ensuring that every viewer's contribution is celebrated in a fun and engaging way.",
};

export const rebbeData = {
  name: "rebbeResponsa",
  logo: rebbeLogo,
  viewAppLink: "www.abc.com",
  heading1: "Bringing Rebbe Responsa Online:",
  paragraph1:
    "Osfy’s Journey to Digitizing a Treasure Trove of Letters \n At Osfy, we’re all about creating software that doesn’t just work—it feels right. When the opportunity came to work on Rebbe Responsa, we jumped in with both feet. This project has been all about taking the Rebbe’s extensive collection of letters and putting them in the palms of your hands, literally. We’re talking thousands of letters filled with advice, inspiration, and guidance, now just a few taps away through a modern, easy-to-use mobile app.",
  heading2: "The Vision Behind Rebbe Responsa",
  paragraph2:
    " Rebbe Responsa is more than just a project; it’s a bridge between the past and the future. For years, the Rebbe’s letters have been a source of wisdom for so many, but accessing these letters in their original format wasn’t always practical. Imagine sifting through stacks of printed pages or bulky books just to find a single letter—that’s where the need for a digital transformation came in.",
  image1: rebbeImage1,
  paragraph3:
    "The goal? To make sure that the guidance found in Rebbe Responsa is always within reach, no matter where you are. We wanted to take something that’s been a bit hard to access and turn it into a convenient digital library where users can easily search, browse, and find exactly what they’re looking for, whenever they need it",
  heading3: "Osfy’s Role: Crafting the Experience from Start to Finish",


  orderedList1: [
    {
      description:
        "When the Rebbe Responsa team reached out to us, they had one big challenge in mind: taking this vast collection and making it easy to navigate, right on your phone. That’s where we stepped in. Here’s what we brought to the table:",
      unorderedList: [
        "Complete Development from the Ground Up: We handled everything, building the app’s backend and frontend from scratch. The result? A smooth, responsive app that feels right at home on any mobile device.",
        "Design that Makes a Difference: We didn’t just throw together a basic layout—we carefully designed the app’s interface to be as user-friendly as possible. Our approach was to keep things clean, straightforward, and focused, so users can find what they’re searching for without any fuss.",
        "Mobile-Centric with Push Notifications: Since there’s currently only a mobile app (no desktop version just yet), we made sure the experience was fully optimized for mobile users. And to keep things lively, the app sends out push notifications, letting you know when new content is added or there’s something interesting you might want to check out.",
        "Built-in Search Engines for Quick Finds: Searching through thousands of letters can sound overwhelming, but not with our search functionality. We implemented a series of search engines that let you narrow down your search by topic, date, phrases, and more. It’s like having a personal librarian at your fingertips.",
        "Content Management Made Simple: We didn’t just stop at the user side. For the administrators managing the app, we built an app portal to easily add new letters, keep content updated, and even track what’s going on within the app..",
      ],
    },
  ],
  image2: rebbeImage2,
  heading4: "Making It Easy for Users to Connect",
  orderedList2: [
    {
      description:
        "With Rebbe Responsa, everything is designed to help you find what you need, fast. Here’s what we did to keep the experience as smooth as possible:",
      unorderedList: [
        "Smart Categorization: We organized the letters into easy-to-browse categories. Whether you’re interested in davening, personal guidance, or learning, there’s a category that’ll get you where you need to go quickly.",
        "Reading Your Way: We know that everyone likes to read differently, so we made sure the app allows you to customize things like text size and display settings for a comfortable reading experience.",
        "Always Up-to-Date Content: Thanks to the app portal, adding new content is no hassle at all, so the collection keeps growing. Whether there’s a newly discovered letter or just an update, the app stays current.",
      ],
    },
  ],
  image3: rebbeImage3,
  heading5: "Why Go Digital with Rebbe Responsa?",
  paragraph4: "It’s more than just putting letters on a screen; it’s about transforming the way people interact with these teachings. Having this knowledge accessible in a mobile app means users can dive into the Rebbe’s insights wherever they are—whether that’s during a quick break, while commuting, or whenever they feel the need for guidance. It’s not just an app; it’s an entirely new way to connect with the Rebbe’s wisdom.\n Osfy’s Touch: More Than Just Development \n At Osfy, we don’t just build software; we craft experiences. For Rebbe Responsa, we made sure every detail was handled with care, from the look and feel of the app to the technical backend that makes it all work. It’s this combination of thoughtful design and robust development that sets our projects apart. We’ve loved being part of this journey to make the Rebbe’s letters more accessible, and we’re excited to see how the app will continue to grow and inspire its users."

  
};
