import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ExternalLink } from "lucide-react";
import { FaArrowRightLong } from "react-icons/fa6";

const ProjectCard = ({ desc, image, logo, link, previewText }) => {
  const location = useLocation();
  const isBaseUrl = location.pathname === "/";

  return (
    <div className="flex flex-row justify-between border-2 border-[#DADADA] rounded-[15px] sm:rounded-3xl h-[179px] sm:h-[270px] lg:h-[330px] xl:h-[400px] w-full">
      <div className="flex flex-col justify-between py-2 pl-3 sm:p-4 md:p-6 lg:pl-8 w-1/2 gap-1 sm:gap-6 md:gap-3">
        <img
          src={logo}
          alt="Company logo"
          className="w-[65px] h-[45px] sm:w-[100.23px] sm:h-[69.11px] lg:w-[142px] lg:h-[99px] object-contain"
        />

        <p className="text-[#555555] flex-grow overflow-hidden">
          {isBaseUrl ? (
            <span className="text-[9px] xs:text-[12px] md:text-base">
              {previewText}
            </span>
          ) : (
            <>
              <span className="block text-[9px] xs:text-[12px] md:text-base xl:hidden">
                {previewText}
              </span>
              <span className="hidden xl:block text-base">
                {desc}
              </span>
            </>
          )}
        </p>

        <Link
          className="sm:hidden text-[#1961FD] font-bold flex items-center gap-2 text-[10px] xxs:text-xs"
          to={`/projects/${link}`}
        >
          View <ExternalLink size={14} />
        </Link>

        <Link
          className="hidden text-[#000000] sm:flex items-center gap-2 text-xl lg:text-2xl"
          style={{
            fontFamily: "Be Vietnam",
            fontWeight: 500,
            lineHeight: "26px",
          }}
          to={`/projects/${link}`}
        >
          <FaArrowRightLong />
          <span>View work</span>
        </Link>
      </div>

      <div className="flex flex-col w-[70%] sm:w-1/2 justify-end h-full rounded-[15px] sm:rounded-3xl">
        <img
          src={image}
          alt="Project image"
          className="w-full max-h-full object-contain"
        />
      </div>
    </div>
  );
};

export default ProjectCard;