import React from "react";
import GridItem from "../components/common/gridItem";
import pos from "../assets/images/pos.png";
import tent from "../assets/images/tent.png";
import leaf from "../assets/images/leaf.png";
import nick from "../assets/images/nick.png";
import pms from "../assets/images/pms.png";
import hebrew from "../assets/images/hebrew.png";
import posC from "../assets/images/posC.png";
import tentC from "../assets/images/tentC.png";
import leafC from "../assets/images/leafC.png";
import nickC from "../assets/images/nickC.png";
import pmsC from "../assets/images/pmsC.png";
import hebrewC from "../assets/images/hebrewC.png";

function OurClient() {
  return (
    <section
      id="services"
      className="flex flex-col justify-center items-center gap-10 sm:gap-20 mb-[80px]"
    >
      <h3 className="px-[5%] md:px-[130px] lg:px-[242px] mx:auto md:mx-0 self-start">
        Our clients
      </h3>
      <div className="w-full flex justify-center">
        <div className="w-[85%] md:w-[60%] grid grid-cols-2 grid-rows-3 md:grid-cols-3 md:grid-rows-2 gap-px bg-gray-300 mx-auto">
          <GridItem
            className="order-1 md:order-1"
            img={pos}
            alt="campos"
            coloredImg={posC}
            width="132px"
            height="92px"
          />
          <GridItem
            className="order-2 md:order-2"
            img={tent}
            alt="tent"
            coloredImg={tentC}
            width="121px"
            height="81px"
          />
          <GridItem
            className="order-5 md:order-3"
            img={leaf}
            alt="leaf"
            coloredImg={leafC}
            width="96px"
            height="68px"
          />
          <GridItem
            className="order-3 md:order-4"
            img={nick}
            alt="nick"
            coloredImg={nickC}
            width="90px"
            height="77px"
          />
          <GridItem
            className="order-4 md:order-5"
            img={pms}
            alt="pms"
            coloredImg={pmsC}
            width="95px"
            height="42px"
          />
          <GridItem
            className="order-6 md:order-6"
            img={hebrew}
            alt="hebrew"
            coloredImg={hebrewC}
            width="64px"
            height="64px"
          />
        </div>
      </div>
    </section>
  );
}

export default OurClient;
