import posImage from "../assets/images/projectsImg/campos/posImage1.png";
import posLogo from "../assets/images/projectsImg/campos/posLogo.png";
import displayScreenLogo from "../assets/images/projectsImg/displayScreen/displayScreenLogo.png"
import displayScreen from "../assets/images/projectsImg/displayScreen/displayScreen.webp"
import rebbe from "../assets/images/projectsImg/rebbe/rebbe.webp"
import rebbeLogo from "../assets/images/projectsImg/rebbe/rebbeLogo.png"
import accountMangement from "../assets/services/accountManagement.png";
import dataSecurity from "../assets/services/dataSecurity.png";
import paymentIntegration from "../assets/services/paymentIntegration.png";
import responsive from "../assets/services/responsive.png";
import testing from "../assets/services/testing.png";
import uiux from "../assets/services/uiux.png";

export const projectData = [
  {
    id: 1,
    projectImg: posImage,
    projectLogo: posLogo,
    description:
      "Streamline camp canteen operations with CampOS. From managing inventory to processing payments, our app offers comprehensive features to optimize efficiency and enhance customer satisfaction.",
    previewText: "Streamline camp canteen operations with CampOS.",
    projectName: "CAMPOS",
    projectRouteName: "campos",
  },
  {
    id: 2,
    projectImg: displayScreen,
    projectLogo: displayScreenLogo,
    description:
      "Our digital system replaces costly PVC boards, showcasing dedications on screens with instant updates and easy management, ensuring seamless recognition for every viewer.",
    previewText:"Giving Dedications a Fresh Look: The New Digital Experience at the 770 Tent",
    projectName: "Display Screen",
    projectRouteName: "displayScreen",
  },
  {
    id: 3,
    projectImg: rebbe,
    projectLogo: rebbeLogo,
    description:
      "We transformed access to the Rebbe’s letters, offering a streamlined, mobile-friendly experience. With user-friendly search features, customizable reading settings, and push notifications, users can explore insights easily and stay updated.",
    previewText: "Rebbe Responsa Digitizing a Treasure Trove of Letters",
    projectName: "Rebbe Responsa",
    projectRouteName: "rebbeResponsa",
  },
];

export const services = [
  {
    id: 1,
    title: "UI/UX Design",
    serviceImg: uiux,
  },
  {
    id: 2,
    title: "Account managament",
    serviceImg: accountMangement,
  },
  {
    id: 3,
    title: "Responsive Design",
    serviceImg: responsive,
  },
  {
    id: 4,
    title: "Payment Integration",
    serviceImg: paymentIntegration,
  },
  {
    id: 5,
    title: "Data Security",
    serviceImg: dataSecurity,
  },
  {
    id: 6,
    title: "Testing & Debugging",
    serviceImg: testing,
  },
];
