import React from "react";
import HorizontalSlider from "../components/HorizontalSlider";
import LatestWorkCard from "../components/common/latestWorkCard";
import posLogo from "../assets/images/projectsImg/campos/posLogo.png";
import posImage from "../assets/images/projectsImg/campos/posImage.webp";
import CButton from "../components/common/CButton";
import { projectData } from "../data";
import ProjectCard from "../components/ProjectCard";
function LatestWork() {
  return (
    <section
      id="projects"
      className="flex flex-col justify-center items-center gap-6 sm:gap-20"
    >
      <h3 className="px-[5%] md:px-[130px] lg:px-[242px] mx:auto md:mx-0  self-start">
        Latest <span className="text-[#1961FD]">work</span>
      </h3>
      <div className="w-full px-[5%] md:ps-[16%] md:pr-[10%] mb-10">
        <HorizontalSlider>
          {projectData.map((project, index) => (
            <div>
              <div className="flex-shrink-0 flex items-center gap-4 sm:gap-10 py-4 sm:py-8 px-2 md:px-10  rounded-3xl w-[92vw]  md:w-[89%] lg:min-w-[700px] sm:max-w-none h-[200px] md:h-auto">
                <ProjectCard
                  logo={project.projectLogo}
                  image={project.projectImg}
                  desc={project.description}
                  projectName={project.projectName}
                  projectLogo={project.projectLogo}
                  key={index}
                  link={project.projectRouteName}
                  previewText={project.previewText}
                />
              </div>
            </div>
          ))}
        </HorizontalSlider>
      </div>
      <div className="justify-center items-center hidden sm:block mb-20">
        <CButton name="View all work" to={"/projects"} />
      </div>
    </section>
  );
}

export default LatestWork;
