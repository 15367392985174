import React from "react";

function ListWithTitle({ hideNumbering=false, orderedList , leadingImg = false , image}) {
  return (
    <div className="mt-10">
       {
        leadingImg && image && (
            <img src={image} alt="image" className="w-[100%] h-[auto] mb-10 mt-5" />
        )
      }
      <ol className={`${hideNumbering? "list-none" : "list-decimal"} pl-5 mt-4`}>
        {orderedList?.map((item, index) => (
          <li key={index} className="mb-5 text-sm md:text-xl font-semibold" >
            <div className="paraSubTitle  md:mb-4">{item?.item}</div>
            <div className="paraText mb-5 -ml-5">{item?.description}</div>
            <ul className="list-disc pl-6 mt-2">
              {item?.unorderedList?.map((subItem, subIndex) => (
                <li key={subIndex} className="paraText">{subItem}</li>
              ))}
            </ul>
          </li>
        ))}
      </ol>
      {!leadingImg && image && (
            <img src={image} alt="image" className="w-[100%] h-[auto] mt-10" />
        )}
      
    </div>
  );
}

export default ListWithTitle;