import React from "react";
import ParagraphWithTitle from "../common/paragraphWithTitle";
import ListWithTitle from "../common/listWithTitle";

function DisplayScreens({ projectData }) {
  return (
    <div>
    <ParagraphWithTitle
      heading={projectData.heading1}
      para={projectData.paragraph1}
    />
    <ParagraphWithTitle heading={projectData.heading2} />
    <ListWithTitle orderedList={projectData.orderedList1} hideNumbering={true}/>

    <ParagraphWithTitle
      heading={projectData.heading3}
      para={projectData.paragraph2}
      image={projectData.image1}
      leadingImg={true}
    />

    <ParagraphWithTitle
      heading={projectData.heading4}
    />
    <ListWithTitle orderedList={projectData.orderedList2} hideNumbering={true} image ={projectData.image2}/>

    <ParagraphWithTitle
      heading={projectData.heading5}
    />
    <ListWithTitle orderedList={projectData.orderedList3} hideNumbering={true} />

    <ParagraphWithTitle
      heading={projectData.heading6}
      para={projectData.paragraph3}
    />
    
   
  </div>
   
  );
}

export default DisplayScreens;
