import React from "react";
import { FiCornerDownRight } from "react-icons/fi";
import CButton from "../common/CButton";
import blueCircle from "../../assets/blueCircle.svg";
import osfyLogo from "../../assets/osfyLogo.svg";
import io from "../../assets/IO.svg";
import GridItemWithHeading from "../common/gridItemWithHeading";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="relative bg-white overflow-hidden">
      <section
        id="contact"
        className="w-full md:h-[80vh] lg:h-[60vh] px-10 md:ps-[16%] md:pe-[15%] lg:pe-[30%] pt-10 pb-[5%] flex flex-col md:flex-row md:justify-between  md:space-y-0"
      >
        <div className="flex flex-col items-start ">
          <div className="flex-grow flex md:flex-col items-center gap-10 md:items-start">
            <div className="text-center text-2xl font-bold md:text-left  md:mb-0">
              <span className="text-[#1961FD]">Connect</span> <br /> with us.
            </div>
            <div className="flex items-center gap-4 md:gap-9  md:mt-7  md:flex ">
              <div className="hidden md:inline">
                <FiCornerDownRight size={60} color="#1961FD" />
              </div>
              <CButton name="Connect" />
            </div>
          </div>
          <div className="hidden md:flex md:flex-col">
            <div className="text-start font-bold">Or Email us at</div>

            <a
              href="mailto:help@osfy.com"
              className="text-blue-400 hover:text-blue-500 transition-colors duration-300"
            >
              hello@Osfy.com
            </a>
          </div>
        </div>

        <div className="w-full flex mt-10 mb-20 md:hidden">
          <div className="w-full grid grid-rows-2 grid-cols-2 gap-8">
            <GridItemWithHeading heading={"General Inquiries"} link={"hello@osfy.io"}/>
            <GridItemWithHeading heading={"Sales"} link={"sales@osfy.io"}/>
            <GridItemWithHeading heading={"Development"} link={"Dev@osfy.io"}/>
            <GridItemWithHeading heading={"Support"} link={"support@osfy.io"}/>
          </div>
        </div>

        <div className="w-full flex justify-start ml-2 md:hidden">
          <div className="w-3/4 grid grid-rows-2 grid-cols-2 gap-2">
            <a href="#about" >
              About
            </a>
            <a href="#services" >
              Services
            </a>
            <a href="#projects" >
              Our work
            </a>
            <a href="#contact" >
              Contact
            </a>
          </div>
        </div>

        <div className="ml-8 mb-[-20px] md:hidden">
          <img src={osfyLogo} alt="osfy logo" className="w-20 h-20 inline" />
          <img src={io} alt="io logo" className="w-9 h-8 inline ml-[-5`px]" />
        </div>

        <div className="hidden md:flex flex-col items-center md:items-start">
          <div className="mb-4 md:mb-8 font-semibold">Menu</div>
          <ul className="text-sm text-center md:text-left">
            <li className="mb-3">
              <a
                href="#about"
                className="hover:text-blue-400 transition-colors duration-300"
              >
                About
              </a>
            </li>
            <li className="mb-3">
              <a
                href="#projects"
                className="hover:text-blue-400 transition-colors duration-300"
              >
                Our work
              </a>
            </li>
            <li className="mb-3">
              <a
                href="#services"
                className="hover:text-blue-400 transition-colors duration-300"
              >
                Services
              </a>
            </li>
            <li className="mb-3">
              <a
                href="#contact"
                className="hover:text-blue-400 transition-colors duration-300"
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
        <div className="hidden md:flex flex-col items-center md:items-start">
          <div className="font-semibold mb-4 md:mb-8">Office</div>
          <div className="text-sm text-center md:text-left">
            <div className="mb-3">29 Norman Ave</div>
            <div>Brooklyn, NY 11222</div>
          </div>
        </div>
      </section>
      <div>
        <img
          src={blueCircle}
          alt="blue circle"
          className="absolute -bottom-1 -right-[60px]"
        />
      </div>
    </footer>
  );
};

export default Footer;
