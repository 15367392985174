import React from "react";
import Logo from "../common/Logo";
import { useNavigate } from "react-router-dom";

const DrawerList = ({ navLinks, handleNavigation, onClose }) => {
  const navigate = useNavigate();
  return (
    <div className="bg-white w-[80vw]">
      <div
        className="flex justify-center items-center h-[67px] border-b border-gray-300"
        onClick={() => {
          onClose();
          navigate("/");
        }}
      >
        <Logo />
      </div>

      <ul>
        <li>
          <div
            className="flex items-center justify-start py-4 px-10 cursor-pointer hover:bg-gray-100"
            onClick={() => {
              onClose();
              navigate("/");
            }}
          >
            <span
              style={{
                lineHeight: "1.5",
                fontWeight: "500",
                fontSize: "1.5em",
                color: "rgba(0,0,0,0.87)",
                width: "100%",
              }}
            >
              Home
            </span>
          </div>
        </li>
        {navLinks.map((link, index) => (
          <li key={index}>
            <div
              className="flex items-center justify-start py-4 px-10 cursor-pointer hover:bg-gray-100"
              onClick={() => handleNavigation(link.to)}
            >
              <span
                style={{
                  lineHeight: "1.5",
                  fontWeight: "500",
                  fontSize: "1.5em",
                  color: "rgba(0,0,0,0.87)",
                  width: "100%",
                }}
              >
                {link.label}
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DrawerList;
