import React from "react";
import { Link } from "react-router-dom";

const CButton = ({ name, to }) => {
  return (
    <Link to={to} className="bg-[#1965FD] py-4 px-10">
      <span
        className="text-white font-medium text-[20px]"
        style={{
          lineHeight: "20px",
          textAlign: "center",
        }}
      >
        {name}
      </span>
    </Link>
  );
};

export default CButton;
