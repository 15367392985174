import React from "react";
import ParagraphWithTitle from "../common/paragraphWithTitle";
import ListWithTitle from "../common/listWithTitle";

function RebbeResponsa({ projectData }) {
  return (
    <div>
      <ParagraphWithTitle
        heading={projectData.heading1}
        para={projectData.paragraph1}
      />

      <ParagraphWithTitle
        heading={projectData.heading2}
        para={projectData.paragraph2}
        image={projectData.image1}
      />

      <ParagraphWithTitle para={projectData.paragraph3} />

      <ParagraphWithTitle heading={projectData.heading3} />
      <ListWithTitle
        orderedList={projectData.orderedList1}
        hideNumbering={true}
        image={projectData.image2}
      />
      <ParagraphWithTitle heading={projectData.heading4} />
      <ListWithTitle
        orderedList={projectData.orderedList2}
        hideNumbering={true}
        image={projectData.image3}
      />
      <ParagraphWithTitle
        heading={projectData.heading5}
        para={projectData.paragraph4}
      />
    </div>
  );
}

export default RebbeResponsa;
