import React, { useState } from "react";

const GridItem = ({ img, alt, coloredImg, width, height , className}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={`flex justify-center items-center bg-white px-10 py-5 md:p-10 ${className}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={isHovered ? coloredImg : img}
        alt={alt}
        style={{
          width: width,
          height: height,
        }}
        className="transition-all duration-300 ease-in-out md:scale-150 object-contain"
      />
    </div>
  );
};

export default GridItem;
