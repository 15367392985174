import React from "react";
import ParagraphWithTitle from "../common/paragraphWithTitle"
import ListWithTitle from "../common/listWithTitle"
import Services from "../Services"


function Campos({ projectData }) {
  return (
    <div>
      <ParagraphWithTitle
        heading={projectData.heading1}
        para={projectData.paragraph1}
      />
      <ParagraphWithTitle
        heading={projectData.heading2}
        para={projectData.paragraph2}
        image={projectData.image1}
      />
      <ListWithTitle orderedList={projectData.orderedList1} />
      <ParagraphWithTitle
        heading={projectData.heading3}
        para={projectData.paragraph3}
        image={projectData.image2}
      />
      <ListWithTitle orderedList={projectData.orderedList2} />

      <ParagraphWithTitle
        heading={projectData.heading4}
        para={projectData.paragraph4}
        leadingImg={true}
        image={projectData.image3}
      />
      <Services />
    </div>
  );
}

export default Campos;
