import React from "react";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import CButton from "../components/common/CButton";
import DesktopAnimation from "../components/DesktopAnimation";

const Main = () => {
  const [text] = useTypewriter({
    words: ["Solutions", "Power", "Experiences", "Brilliance", "Innovations"],
    loop: true,
    delaySpeed: 2000,
  });

  return (
    <div className="flex flex-col mb-10">
      <div className="flex mt-20 sm:mt-10 lg:mt-16 xl:hidden overflow-hidden">
        <DesktopAnimation />
      </div>

      <div className="flex flex-row w-full mx-auto px-[5%] md:px-[90px] lg:h-screen gap-10 lg:gap-32">
        <div className="hidden md:flex items-center justify-center w-[24px]">
          <span
            className="fixed z-50 font-semibold text-[12px] xl:text-[14px] leading-[24px] tracking-[0.3em] text-center whitespace-nowrap"
            style={{
              transform: "rotate(-90deg)",
              color: "#606E7E",
            }}
          >
            APP DEVELOPMENT COMPANY
          </span>
        </div>

        <div className="flex flex-col justify-center gap-[31px]">
          <h1>
            <span className="text-[#071C35]">Crafting Digital</span>
            <br />
            <span className="text-[#1961FD]">{text}</span>
            <Cursor cursorColor="#1961FD" />
            <span className="text-[#404858]">.</span>
          </h1>

          <div className="py-4">
            <h6>Your Vision, Our Expertise.</h6>
            <h6 className="max-w-[700px]">
              Your unique vision combined with our technical expertise creates a
              powerful partnership for app development success.
            </h6>
          </div>

          <div className="hidden sm:flex mt-4">
            <CButton name="Contact us" to="/" />
          </div>
        </div>

        <div className="hidden xl:flex w-[40vw] h-[100vh] absolute right-0 overflow-hidden">
          <DesktopAnimation />
        </div>
      </div>
    </div>
  );
};

export default Main;
