// App.js
import React from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import Home from "./pages/Home";
import { camposData, rebbeData, displayScreensData } from "./data/projectDetailsData";
import ProjectDetails from "./pages/ProjectDetails";
import Projects from "./pages/Projects";
import ScrollToTop from "./components/scrollTop";
import { useParams } from "react-router-dom";

const projectDataMapping = {
  campos: camposData,
  rebbeResponsa: rebbeData,
  displayScreen: displayScreensData,
};



const App = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/:project_name" element={<DynamicProjectDetails />} />
      </Routes>
    </>
  );
};


const DynamicProjectDetails = () => {
  const { project_name } = useParams(); 
  const projectData = projectDataMapping[project_name] || {};
  return <ProjectDetails projectData={projectData} />;
};

export default App;
