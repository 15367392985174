import React from "react";
import osfy from "../assets/osfyLogo.svg";
import { services } from "../data";

const Services = () => {
  const breakTitleIntoTwoLines = (title) => {
    const words = title.split(" ");
    const midpoint = Math.ceil(words.length / 2);
    const firstLine = words.slice(0, midpoint).join(" ");
    const secondLine = words.slice(midpoint).join(" ");
    return (
      <>
        {firstLine}
        <br />
        {secondLine}
      </>
    );
  };

  return (
    <div
      className="flex flex-col rounded-[40px] p-6 mt-7 md:m-7 items-center"
      style={{
        boxShadow: "0px 2px 5px 0px #00000040",
      }}
    >
      <img src={osfy} alt="osfy" className="w-20 h-20" />
      <div className="text-center uppercase">
        <span
          className=""
          style={{
            fontSize: "24px",
            fontWeight: "700",
            lineHeight: "36px",
            color: "#1961FD",
          }}
        >
          Services provided
        </span>
        <br />
        <span
          style={{
            fontSize: "17px",
            fontWeight: "600",
            lineHeight: "29px",
            color: "#818181",
          }}
        >
          For this Project
        </span>
      </div>

      <div className="grid grid-cols-2 sm:grid-cols-3 my-10 gap-5 w-full">
        {services.map((service, ind) => (
          <div
            key={ind}
            className="flex flex-col items-center justify-center text-center"
          >
            <img
              src={service.serviceImg}
              alt={service.title}
              className="w-24 h-24"
            />
            <span
              className="uppercase"
              style={{
                fontSize: "12px",
                fontWeight: "700",
                lineHeight: "16px",
                color: "#000000",
              }}
            >
              {breakTitleIntoTwoLines(service.title)}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
