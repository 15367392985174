import React from "react";

function ParagraphWithTitle(props) {
  return (
    <div className="mt-10">
      {
        props.leadingImg && props.image && (
            <img src={props.image} alt="image" className="w-[100%] h-[auto] mb-10" />
        )
      }
      <div  className="paraTitle mb-1 md:mb-8">{props.heading}</div>
      <p className="paraText">
        {props.para && props.para.split('\n').map((line, index) => (
          <span key={index}>
            {line}
            <br /> 
            <br />
          </span>
        ))}
      </p>
        {!props.leadingImg && props.image && (
            <img src={props.image} alt="image" className="w-[100%] h-[auto] " />
        )}
    </div>
  );
}

export default ParagraphWithTitle;
