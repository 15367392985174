import React from "react";
import OurClient from "../section/OurClient";
import Layout from "../components/layout/Layout";
import Main from "../section/Main";
import Think from "../section/Think";
import LatestWork from "../section/LatestWork";

const Home = () => {
  return (
    <Layout>
      <Main />
      <Think />
      <OurClient />
      <LatestWork />
    </Layout>
  );
};

export default Home;
