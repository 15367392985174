import React, { useState, useRef, useEffect } from 'react';
import { ChevronRight, ChevronLeft } from 'lucide-react';

const HorizontalSlider = ({ children }) => {
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const containerRef = useRef(null);
  const autoSlideIntervalRef = useRef(null);

  const checkOverflow = () => {
    const container = containerRef.current;
    if (container) {
      const newIsMobile = window.innerWidth < 768;
      setIsMobile(newIsMobile);
      if (newIsMobile) {
        setShowLeftArrow(false);
        setShowRightArrow(false);
      } else {
        setShowRightArrow(container.scrollWidth > container.clientWidth);
        setShowLeftArrow(container.scrollLeft > 0);
      }
    }
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [children, currentIndex]);

  useEffect(() => {
    if (isMobile) {
      startAutoSlide();
    } else {
      stopAutoSlide();
    }
    return () => stopAutoSlide();
  }, [isMobile]);

  const handleScroll = () => {
    if (!isMobile) {
      checkOverflow();
    }
  };

  const slide = (direction) => {
    const container = containerRef.current;
    if (container) {
      if (isMobile) {
        setCurrentIndex(prev => {
          const nextIndex = direction === 'right' 
            ? (prev + 1) % React.Children.count(children)
            : (prev - 1 + React.Children.count(children)) % React.Children.count(children);
          return nextIndex;
        });
      } else {
        const scrollAmount = direction === 'right' ? container.clientWidth - 100 : -container.clientWidth + 100;
        container.scrollBy({
          left: scrollAmount,
          behavior: 'smooth'
        });
      }
    }
  };

  const startAutoSlide = () => {
    stopAutoSlide();
    autoSlideIntervalRef.current = setInterval(() => {
      slide('right');
    }, 2000); // Change slide every 3 seconds
  };

  const stopAutoSlide = () => {
    if (autoSlideIntervalRef.current) {
      clearInterval(autoSlideIntervalRef.current);
    }
  };

  return (
    <div className="relative w-full overflow-hidden">
      <div
        ref={containerRef}
        className="flex transition-all duration-500 ease-in-out"
        style={{
          transform: isMobile ? `translateX(-${currentIndex * 100}%)` : 'none',
          overflowX: isMobile ? 'visible' : 'auto',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          willChange: 'transform'
        }}
        onScroll={handleScroll}
      >
        {React.Children.map(children, (child) => (
          <div className="flex-shrink-0 w-full md:w-[92%] transition-opacity duration-500 ease-in-out">
            {child}
          </div>
        ))}
      </div>
      {!isMobile && showLeftArrow && (
        <button
          onClick={() => slide('left')}
          className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 p-2 rounded-full shadow-md z-10 transition-opacity duration-300 hover:bg-opacity-75"
        >
          <ChevronLeft className="w-6 h-6 text-gray-600" />
        </button>
      )}
      {!isMobile && showRightArrow && (
        <button
          onClick={() => slide('right')}
          className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 p-2 rounded-full shadow-md z-10 transition-opacity duration-300 hover:bg-opacity-75"
        >
          <ChevronRight className="w-6 h-6 text-gray-600" />
        </button>
      )}
    </div>
  );
};

export default HorizontalSlider;