import React from "react";
import mobileImg from "../assets/thinkMobileBg.webp";
import desktopImg from "../assets/thinkDesktop.webp";
import blueVector from "../assets/blueVector.svg";
import yellowVector from "../assets/yellowVector.svg";

const ThinkImage = () => {
  return (
    <div className="relative flex justify-center items-center w-full h-full">
      <div className="absolute inset-0 z-0">
        <img 
          src={blueVector} 
          alt="Blue Vector" 
          className="absolute right-0 top-0 w-[189px] h-[181px] md:w-[250px] md:h-[220px] lg:w-[297px] lg:h-[283px]"
        />
        <img 
          src={yellowVector} 
          alt="Yellow Vector" 
          className="absolute left-0 bottom-0 w-[224px] h-[213px] xl:bottom-[50px]"
        />
      </div>
      
      <div className="relative z-10 w-full h-full">
        <img
          src={mobileImg}
          alt="Mobile Background"
          className="w-full h-full object-cover md:hidden p-10"
        />
        <img
          src={desktopImg}
          alt="Desktop Background"
          className="hidden w-full h-full object-cover md:block"
        />
      </div>
    </div>
  );
};

export default ThinkImage;