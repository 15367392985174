import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "../../assets/Sort.png";
import Logo from "../common/Logo";
import { Drawer } from "@mui/material";
import DrawerList from "./DrawerList";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);

  const navLinks = [
    { label: "Project", to: "/projects" },
    { label: "About", to: "about" },
    { label: "Contact", to: "contact" },
  ];

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    window.history.pushState(null, "", `#${id}`);
    setIsOpen(false);
  };

  const handleNavigation = (to) => {
    if (to === "/projects") {
      setIsOpen(false);
      navigate(to);
    } else {
      handleScroll(to);
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };

  useEffect(() => {
    const handleScrollEvent = () => {
      if (window.scrollY > 10) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScrollEvent);

    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, []);

  return (
    <nav
      className={`bg-white w-full z-50 fixed sm:static top-0 left-0 flex items-center h-[67px] sm:h-auto sm:py-[30px] ${
        isScrolled ? "shadow-[0px_1px_14px_0px_#00000040]" : ""
      } sm:shadow-none transition-shadow duration-300`}
    >
      <div className="relative flex justify-center sm:justify-between items-center w-full mx-auto px-[50px] md:px-[90px]">
        <div className="absolute left-[27px] sm:hidden">
          <button
            onClick={toggleDrawer(true)}
            className="focus:outline-none h-6 w-6 hover:bg-gray-100 rounded-md flex items-center justify-center"
          >
            <img src={Menu} alt="menu" />
          </button>
        </div>

        <div onClick={() => navigate("/")}>
          <Logo />
        </div>

        <ul className="hidden sm:flex justify-center space-x-8">
          {navLinks.map((link, index) => (
            <li
              key={index}
              onClick={() => handleNavigation(link.to)}
              className="cursor-pointer hover:bg-[#F8F9FA] hover:rounded-sm px-3 py-1 flex items-center"
            >
              <div
                style={{
                  lineHeight: "22px",
                  fontWeight: "500",
                  fontSize: "22px",
                  color: "#071C35",
                }}
              >
                {link.label}
              </div>
            </li>
          ))}
        </ul>
      </div>

      <Drawer
        anchor="left"
        open={isOpen}
        onClose={toggleDrawer(false)}
        className="sm:hidden"
      >
        <DrawerList
          navLinks={navLinks}
          handleNavigation={handleNavigation}
          onClose={() => setIsOpen(false)}
        />
      </Drawer>
    </nav>
  );
};

export default Navbar;
