import React from "react";
import Layout from "../components/layout/Layout";
import rightArrow from "../assets/images/rightArrow.png";
import Campos from "../components/projects/campos";
import RebbeResponsa from "../components/projects/rebbeResponsa";
import DisplayScreens from "../components/projects/displayScreens";
import { useParams } from "react-router-dom";


function ProjectDetails({ projectData }) {
  const { project_name} = useParams();

  console.log(project_name);
  if (!projectData) {
    return null;
  }

  const renderProjectComponent = () => {
    switch (project_name) {
      case "campos":
        return <Campos projectData={projectData} />;
      case "rebbeResponsa":
        return <RebbeResponsa projectData={projectData} />;
      default:
        return <DisplayScreens projectData={projectData} />;
    }
  };


  

  return (
    <Layout>
      <div className="px-[5%] sm:px-[10%] md:px-[15%] lg:px-[20%] xl:px-[23%] py-[22%] sm:py-[2%] md:[3%]">
        <div className="flex items-center">
          <div className="flex items-center flex-grow">
            <img
              src={projectData.logo}
              alt={projectData.logoAlt}
              className={`w-16 h-16 ${projectData?.logoText ? "md:h-[74px] md:w-24" : "md:w-32 md:h-32"} object-contain mt-[-6px]`}
            />
            {projectData.logoText && (
              <img
                src={projectData.logoText}
                alt="posLogo"
                className="h-[50px] w-[200px] md:h-[96px] md:w-[400px] object-contain ml-[-40px] md:ml-[-75px]"
              />
            )}
          </div>
          <div className="flex items-center gap-2 md:gap-4">
            <div className="bg-[#1961FD] w-[30px] h-[30px] md:w-[50px] md:h-[50px] rounded-[50%] flex items-center justify-center border border-2 border-dotted border-[#1961FD] ">
              {" "}
              <img src={rightArrow} alt="right" className="w-4" />
            </div>
            <a href={projectData.viewAppLink} className="min-w-[90px] paraText">
              Visit App
            </a>
          </div>
        </div>
        <div className="px-5 mt-[30px] md:mt-[60px]">
          {renderProjectComponent()}
        </div>
      </div>
    </Layout>
  );
}

export default ProjectDetails;
