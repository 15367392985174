import React from "react";

function GridItemWithHeading({heading, link}) {
  return (
    <div>
      <div
        style={{
          fontSize: "17px",
          fontWeight: "700",
          lineHeight: "29px",
          letterSpacing: "-0.03em",
          textAlign: "left",
        }}
        
      >
        {heading}
      </div>
      <a
        href={`mailto:${link}`}
        className="text-[#1961FD] hover:text-blue-500 transition-colors duration-300"
      >
        {link}
      </a>
    </div>
  );
}

export default GridItemWithHeading;
