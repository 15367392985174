import React from "react";
import Layout from "../components/layout/Layout";
import { projectData } from "../data";
import ProjectCard from "../components/ProjectCard";

const Projects = () => {
  return (
    <Layout>
      <div className="flex flex-col gap-7 mb-10 px-[5%] md:px-[16%] pt-32">
        <div className="hidden md:flex items-center justify-center w-[24px] h-screen absolute md:left-12 lg:left-24 mt-[-128px]">
          <span
            className="fixed z-50 font-semibold text-[12px] xl:text-[14px] leading-[24px] tracking-[0.3em] text-center whitespace-nowrap"
            style={{
              transform: "rotate(-90deg)",
              color: "#606E7E",
            }}
          >
            APP DEVELOPMENT COMPANY
          </span>
        </div>

        <h1>
          <span className="text-[#071C35]">Projects we’ve</span>
          <span className="text-[#1961FD]"> done</span>
          <span className="text-[#404858]">.</span>
        </h1>

        <h6 className="sm:max-w-[60vw]">
          Explore our portfolio to gain insights into our
          capabilities, problem-solving skills, and commitment to delivering
          exceptional results. Each project highlights the unique challenges we
          overcame, the innovative solutions we implemented,and the positive
          outcomes we achieved.
        </h6>

        <div className="flex flex-col gap-5">
          {projectData.map((project, index) => (
            <div>
              <div>
                <ProjectCard
                  logo={project.projectLogo}
                  image={project.projectImg}
                  desc={project.description}
                  projectName={project.projectName}
                  projectLogo={project.projectLogo}
                  key={index}
                  link={project.projectRouteName}
                  previewText={project.previewText}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Projects;
