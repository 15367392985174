import React from "react";
import ThinkImage from "../components/ThinkImage";

const Think = () => {
  return (
    <section
      id="about"
      className="flex flex-col gap-[32px] md:flex-row w-full mx-auto px-[5%] md:mx-0 mb-10"
    >

      <div className="md:w-[50%]">
        <ThinkImage />
      </div>

      <div className="flex flex-col gap-[14px] sm:gap-[20px] md:gap-[48px] md:w-[50%]">
        <h2>
          <span className="text-[#222222]">We think</span>
          <br />
          <span className="text-[#1961FD]">differently</span>
          <span className="text-[#222222]">.</span>
        </h2>

        <div className="py-4">
          <h6 className="max-w-[500px]">
            Your imagination is the limit. Our expertise is the key to turning
            your app dreams into a tangible reality.
          </h6>
        </div>
      </div>
    </section>
  );
};

export default Think;
